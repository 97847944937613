import React from 'react'
import { CSSProp } from 'styled-components'
import { cmnStyle } from './homeCommon.style'
import { style } from './WhatIs.style'
import Content from '../Content/Content'
import SectionTitle from '../SectioinTitle'

declare module 'react' {
  interface Attributes {
    css?: CSSProp
  }
}

const WhatIs = () => {
  return (
    <section id="whatis_section">
      <Content>
        <SectionTitle sectionTitle="マイナンバーカードとは" />
        <p css={style.txt}>
          マイナンバーカードは、社会保障・税番号（マイナンバー）制度の開始に伴い交付を開始した、顔写真付きのICチップ搭載プラスチック製カードです。本人確認のための身分証明書として利用できるほか、各種手続がスムーズになるなど、社会生活の様々な用途で利用が広がっています。
        </p>
      </Content>
    </section>
  )
}

export default WhatIs
