import React from 'react'
import { CSSProp } from 'styled-components'
import getConfig from 'next/config'

import { style } from './Merit.style'
import { cmnStyle } from './homeCommon.style'
import Content from '../Content/Content'
import SectionTitle from '../SectioinTitle'

const { publicRuntimeConfig } = getConfig()
const basePath = publicRuntimeConfig.basePath

declare module 'react' {
  interface Attributes {
    css?: CSSProp
  }
}

const Merit = () => {
  return (
    <section id="merit_section">
      <Content>
        <SectionTitle sectionTitle="マイナンバーカード取得のメリット" />
        <img
          src={`${basePath}/home/img/merit01.png`}
          alt="どんなメリットがあるの どこで使えるの"
          css={style.topImg}
        />
        <p css={style.subTitle}>
          <strong css={cmnStyle.em01}>
            マイナンバーカードが利用できる場面が増えています
          </strong>
        </p>
        <article css={style.card}>
          <div css={style.txtBlock}>
            <header>
              <h4>メリット1</h4>
            </header>
            <p>
              子どもから高齢者まで使える
              <em css={style.emphasized}>顔写真付きの身分証明書</em>になる
            </p>
          </div>
          <div css={style.imgBlock}>
            <img src={`${basePath}/home/img/merit02.png`} />
          </div>
        </article>
        <article css={style.card}>
          <div css={style.txtBlock}>
            <header>
              <h4>メリット2</h4>
            </header>
            <p>
              スマートフォンやパソコンで
              <em css={style.emphasized}>確定申告などの行政手続き</em>
              ができる
            </p>
            <small>
              ※カードの読み取りに対応できるスマートフォンまたはカードリーダーが必要
            </small>
            <small>
              ※顔認証マイナンバーカードは利用できません。
            </small>
          </div>
          <div css={style.imgBlock}>
            <img src={`${basePath}/home/img/merit03.png`} />
          </div>
        </article>
        <article css={style.card}>
          <div css={style.txtBlock}>
            <header>
              <h4>メリット3</h4>
            </header>
            <p>
              コンビニ交付サービスで「
              <em css={style.emphasized}>住民票の写し</em>」や
              <br />「<em css={style.emphasized}>印鑑登録証明書</em>
              」などが取得できる
            </p>
            <small>
              ※顔認証マイナンバーカードは利用できません。
            </small>
          </div>
          <div css={style.imgBlock}>
            <img src={`${basePath}/home/img/merit04.png`} />
          </div>
        </article>
        <div css={style.wrapBtns}>
          <div>
            <a
              href="https://www.city.niigata.lg.jp/kurashi/todokede/konbinikoufu/konbinikoufu.html"
              target="_blank"
              rel="noopener noreferrer"
              css={cmnStyle.btn}
            >
              <span>詳しくはこちら</span>
            </a>
            <p css={cmnStyle.btnDescription}>
              ※外部リンクが開きます。
              <br />
              コンビニ交付サービス（新潟市HP）
            </p>
          </div>
          <div>
            <a
              href="https://www.city.niigata.lg.jp/kurashi/zei/shoumei_eturan/convenience_zei.html"
              target="_blank"
              rel="noopener noreferrer"
              css={cmnStyle.btn}
            >
              <span>詳しくはこちら</span>
            </a>
            <p css={cmnStyle.btnDescription}>
              ※外部リンクが開きます。
              <br />
              課税（所得）証明書&emsp;コンビニ交付サービス（新潟市HP）
            </p>
          </div>
        </div>
        <article css={style.card}>
          <div css={style.txtBlock}>
            <header>
              <h4>メリット4</h4>
            </header>
            <p>
              <em css={style.emphasized}>健康保険証</em>として利用できる
            </p>
            <small>※オンライン窓口「マイナポータル」から申し込みが必要</small>
            <small>※対応する医療機関は今後増加予定</small>
          </div>
          <div css={style.imgBlock}>
            <img src={`${basePath}/home/img/merit05.png`} />
          </div>
        </article>
        <div css={style.wrapBtn}>
          <a
            href="https://www.mhlw.go.jp/stf/newpage_21669.html"
            target="_blank"
            rel="noopener noreferrer"
            css={cmnStyle.btn}
          >
            <span>詳しくはこちら</span>
          </a>
          <p css={cmnStyle.btnDescription}>
            ※外部リンクが開きます。
            <br />
            マイナンバーカード健康保険証利用対応の医療機関リスト（厚生労働省HP）
          </p>
        </div>
        
        
      </Content>
    </section>
  )
}

export default Merit
